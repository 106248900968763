$(document).ready(function () {
    var main_type = 'project';
    var main_skip = 0;
    var main_slug = '';

    $('#main-search-set-type-project').click(function () {
        main_type = 'project';
        $('#main-search-set-type-project').addClass('active');
        $('#main-search-set-type-user').removeClass('active');
        $('#main-search-set-type-resource').removeClass('active');
        $('#main-search-set-type-new').removeClass('active');
        $('#main-search-set-type-event').removeClass('active');
        $('.menu-search-results_counter').hide();
        main_skip = 0;
        $('#main-search').trigger(jQuery.Event('keydown', {keyCode: 13}));
    });
    $('#main-search-set-type-user').click(function () {
        main_type = 'user';
        $('#main-search-set-type-project').removeClass('active');
        $('#main-search-set-type-user').addClass('active');
        $('#main-search-set-type-resource').removeClass('active');
        $('#main-search-set-type-new').removeClass('active');
        $('#main-search-set-type-event').removeClass('active');
        $('.menu-search-results_counter').hide();
        main_skip = 0;
        $('#main-search').trigger(jQuery.Event('keydown', {keyCode: 13}))
    });
    $('#main-search-set-type-resource').click(function () {
        main_type = 'resource';
        $('#main-search-set-type-project').removeClass('active');
        $('#main-search-set-type-user').removeClass('active');
        $('#main-search-set-type-resource').addClass('active');
        $('#main-search-set-type-new').removeClass('active');
        $('#main-search-set-type-event').removeClass('active');
        $('.menu-search-results_counter').hide();
        main_skip = 0;
        $('#main-search').trigger(jQuery.Event('keydown', {keyCode: 13}))
    });
    $('#main-search-set-type-new').click(function () {
        main_type = 'new';
        $('#main-search-set-type-project').removeClass('active');
        $('#main-search-set-type-user').removeClass('active');
        $('#main-search-set-type-resource').removeClass('active');
        $('#main-search-set-type-new').addClass('active');
        $('#main-search-set-type-event').removeClass('active');
        $('.menu-search-results_counter').hide();
        main_skip = 0;
        $('#main-search').trigger(jQuery.Event('keydown', {keyCode: 13}))
    });
    $('#main-search-set-type-event').click(function () {
        main_type = 'event';
        $('#main-search-set-type-project').removeClass('active');
        $('#main-search-set-type-user').removeClass('active');
        $('#main-search-set-type-resource').removeClass('active');
        $('#main-search-set-type-event').addClass('active');
        $('#main-search-set-type-new').removeClass('active');
        $('.menu-search-results_counter').hide();
        main_skip = 0;
        $('#main-search').trigger(jQuery.Event('keydown', {keyCode: 13}))
    });


    function getMainResultSearch(clear) {
        $('#main-search-more').hide();
        $('.preloader').show();
        $.ajax({
            method: "POST",
            url: "/api/main_search",
            data: {
                "slug": main_slug,
                "skip": main_skip,
                "type": main_type,
                "lang": window.lang,
            },
            success: function (response) {
                $('.search-reset').removeClass('active');
                var results = response.results;
                var count = response.count;
                var trans_choice = response.trans_choice;
                if (clear) {
                    var str = '';
                } else {
                    var str = $('.menu-search-result-linkblock').html();
                }

                $('.main-search-count-project').html(response.countProject);
                $('.main-search-count-user').html(response.countUser);
                $('.main-search-count-resource').html(response.countResource);
                $('.main-search-count-new').html(response.countNew);
                $('.main-search-count-event').html(response.countEvent);
                if (main_type == 'project') {
                    for (var i = 0; i < results.length; i++) {
                        if (results[i].cover_image) {
                            str += '<div class="linkblock">';
                        } else {
                            str += '<div class="linkblock noimg">';
                        }
                        str += '<a href="/project/' + results[i].id + '" class="linkblock-table">';
                        if (results[i].cover_image) {
                            str += '<div class="linkblock-img">' +
                                '<img src="/' + results[i].cover_image + '" alt="Обложка">' +
                                '</div>';
                        }
                        str += '<div class="linkblock-counter">' +
                            '<div class="linkblock-innertable">' +
                            '<div class="tr">' +
                            '<div class="linkblock-main">' +
                            '<div class="linkblock-data">' +
                            results[i].date_custom +
                            '</div>' +
                            '<div class="linkblock-link">' +
                            results[i].name +
                            '</div>' +
                            '</div>' +
                            '</div>' +
                            '<div class="tr">' +
                            '<div class="linkblock-cat">';
                        if (results[i].idea) {
                            str += results[i].idea
                        }
                        str += '</div>' +
                            '</div>' +
                            ' </div>' +
                            '</div>' +
                            '</a>' +
                            '</div>';
                    }
                }
                if (main_type == 'user') {
                    for (var i = 0; i < results.length; i++) {
                        if (results[i].photo) {
                            str += '<div class="linkblock">';
                        } else {
                            str += '<div class="linkblock noimg">';
                        }
                        str += '<a href="/profile/' + results[i].id + '" class="linkblock-table">';
                        if (results[i].photo) {
                            str += '<div class="linkblock-img">' +
                                '<img src="/' + results[i].photo + '" alt="Фото">' +
                                '</div>';
                        }
                        str += '<div class="linkblock-counter">' +
                            '<div class="linkblock-innertable">' +
                            '<div class="tr">' +
                            '<div class="linkblock-main">' +
                            '<div class="linkblock-data">';
                        if (results[i].date_reg) {
                            str += results[i].date_reg;
                        }
                        str += '</div>' +
                            '<div class="linkblock-link">' +
                            results[i].name + ' ' + results[i].last_name +
                            '</div>' +
                            '</div>' +
                            '</div>' +
                            '<div class="tr">' +
                            '<div class="linkblock-cat">';
                        if (results[i].about_me) {
                            str += results[i].about_me
                        }

                        str += '</div>' +
                            '</div>' +
                            ' </div>' +
                            '</div>' +
                            '</a>' +
                            '</div>';
                    }
                }
                if (main_type == 'resource') {
                    for (var i = 0; i < results.length; i++) {
                        if (results[i].image) {
                            str += '<div class="linkblock">';
                        } else {
                            str += '<div class="linkblock noimg">';
                        }
                        if (results[i].status === 'Запрошен') {
                            str += '<a href="/requested-resource/' + results[i].id + '" class="linkblock-table">';
                        } else {
                            str += '<a href="/all-resource/' + results[i].id + '" class="linkblock-table">';
                        }
                        if (results[i].image) {
                            str += '<div class="linkblock-img">' +
                                '<img src="/' + results[i].image + '" alt="Картинка">' +
                                '</div>';
                        }
                        str += '<div class="linkblock-counter">' +
                            '<div class="linkblock-innertable">' +
                            '<div class="tr">' +
                            '<div class="linkblock-main">' +
                            '<div class="linkblock-data">' +
                            '<div class="table table-two-cols mb0 nt">' +
                            '<div class="table-two-col">';
                        if (results[i].category_resource) {
                            str += results[i].category_resource;
                        }
                        str += '</div>' +
                            '<div class="table-two-col right brown">' +
                            '</div>' +
                            '</div>' +
                            '</div>' +
                            '<div class="linkblock-link big">' +
                            results[i].name +
                            '</div>' +
                            '<div class="linkblock-description">';
                        if (results[i].short_description) {
                            str += results[i].short_description;
                        }
                        str += '</div>' +
                            '<div class="linkblock-location">';
                        if (results[i].location) {
                            str += results[i].location;
                        }
                        str += '</div>' +
                            '<div class="linkblock-time">';
                        if (results[i].schedule) {
                            str += results[i].schedule;
                        }
                        str += '</div>' +
                            '</div>' +
                            '</div>' +
                            '</div>' +
                            '</div>' +
                            '</div>';
                    }
                }
                if (main_type == 'new') {
                    for (var i = 0; i < results.length; i++) {
                        if (results[i].img) {
                            str += '<div class="linkblock">';
                        } else {
                            str += '<div class="linkblock noimg">';
                        }
                        str += '<a href="/news/' + results[i].id + '" class="linkblock-table">';
                        if (results[i].img) {
                            str += '<div class="linkblock-img">' +
                                '<img src="/' + results[i].img + '" alt="">' +
                                '</div>';
                        }
                        str += '<div class="linkblock-counter">' +
                            '<div class="linkblock-innertable">' +
                            '<div class="tr">' +
                            '<div class="linkblock-main">' +
                            '<div class="linkblock-data">' +
                            results[i].date_custom +
                            '</div>' +
                            '<div class="linkblock-link">' +
                            results[i].title +
                            '</div>' +
                            '</div>' +
                            '</div>' +
                            '<div class="tr">' +
                            '<div class="linkblock-cat">' +
                            results[i].short_text +
                            '</div>' +
                            '</div>' +
                            ' </div>' +
                            '</div>' +
                            '</a>' +
                            '</div>';
                    }
                }
                if (main_type == 'event') {
                    for (var i = 0; i < results.length; i++) {
                        if (results[i].image_url) {
                            str += '<div class="linkblock">';
                        } else {
                            str += '<div class="linkblock noimg">';
                        }
                        str += '<div class="linkblock-table">';
                        if (results[i].image_url) {
                            str += '<a class="linkblock-img" href="/event/' + results[i].id + '">' +
                                '<img src="/' + results[i].image_url + '" alt="">';
                            str += '</a>';
                        }

                        str += '<div class="linkblock-counter">' +
                            '<div class="linkblock-innertable">' +
                            '<div class="tr">' +
                            '<div class="linkblock-main">' +
                            '<div class="linkblock-data">' +
                            results[i].dt_start_formatted +
                            '</div>' +
                            '<a class="linkblock-link" href="/event/' + results[i].id + '">' +
                            results[i].title +
                            '</a>' +
                            '</div>' +
                            '</div>' +
                            '<div class="tr">' +
                            '<div class="linkblock-cat">' +
                            results[i].short_description +
                            '</div>' +
                            '</div>' +
                            ' </div>' +
                            '</div>' +
                            '</div>';
                        str += '</div>';
                    }
                }
                if (results.length) {
                    $('.menu-search-result-linkblock').html(str);
                    $('.menu-search-results_counter').show();
                    $('.menu-search-results_counter').removeClass('none');
                    $('.menu-search-result').addClass('none');
                    $('#main-search-show-result').removeClass('none');
                    $('#none-main-search').addClass('none');
                    $('#main-search-show-no-result-count').addClass('none');
                    $('#main-search-show-result-count').removeClass('none');
                    $('#main-search-show-result-count').html(window.positions.project_filter_results + ' - ' + count + ' ' + trans_choice);

                    $('#main-search-more').show();
                    $('.preloader').hide();
                    if (count < main_skip || results.length < 20) {
                        $('.show_more_search_results').hide();
                    } else {
                        if (count != main_skip + 20) {
                            $('.show_more_search_results').show();
                        } else {
                            $('.show_more_search_results').hide();
                        }

                    }

                } else {
                    $('.menu-search-results_counter').show();
                    $('.menu-search-results_counter').removeClass('none');
                    $('#main-search-show-result').addClass('none');
                    $('#none-main-search').removeClass('none');
                    $('#main-search-show-no-result-count').removeClass('none');
                    $('#main-search-show-result-count').addClass('none');
                    $('.preloader').hide();
                    $('.wrong_q_search_results__mess').html(window.positions.js_we_did_not_find_any_materials_on_request + ' <span>"' + response.slug + '"</span>');
                    // if (response.countProject) {
                    //     $('#main-search-set-type-project').click();
                    //     return;
                    // }
                    // if (response.countUser) {
                    //     $('#main-search-set-type-user').click();
                    //     return;
                    // }
                    // if (response.countResource) {
                    //     $('#main-search-set-type-resource').click();
                    //     return;
                    // }
                    // if (response.countNew) {
                    //     $('#main-search-set-type-new').click();
                    //     return;
                    // }
                    // if (response.countEvent) {
                    //     $('#main-search-set-type-event').click();
                    //
                    // }

                }
                $('.menu-horiz-counter-no-dropdown').css('display', 'block');
            }
        });
    }

    $('.search-submite').click(function () {
        main_slug = $('#main-search').val();
        if (main_slug) {
            $('.site-menu__search').addClass('has_result');
            var clear = 1;
            main_skip = 0;
            getMainResultSearch(clear);
        }
    });

    $('#main-search').keydown(function (e) {
        if (e.keyCode === 13) {
            main_slug = $(this).val();
            if (main_slug) {
                $('.site-menu__search').addClass('has_result');
                var clear = 1;
                main_skip = 0;
                getMainResultSearch(clear);
            }
        }
    });
    $('#main-search-more').click(function () {
        var clear = 0;
        main_skip += 20;
        getMainResultSearch(clear);
    });
});